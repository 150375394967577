$(document).on( window.clickEvent,'.app-card-image-thumbnail', function(event){
	event.preventDefault();
	if( window.drag ) return;
    
    var card = $(this).closest('.card-body');
    var image = $(this).find('img').attr('src');

    card.find('.app-card-image-main').attr('src', image);
    card.find('.app-card-image-thumbnail').removeClass('active');
    $(this).addClass('active');
});