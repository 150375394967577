function initChoices( element, customOptions )
{
    customOptions = ( customOptions !== undefined ) ? JSON.parse(customOptions) : {};

    var options = {
        itemSelectText: '',
        addItems: true,
        allowHTML: true,
    }

    Object.assign(options, customOptions);

    new Choices( element, options);
}
window.initChoices = initChoices;

function initSelect2( element, customOptions )
{
    customOptions = ( customOptions !== undefined ) ? JSON.parse(customOptions) : {};

    var options = {
        "language": {
            "noResults": function(){
                return "Žiadne položky";
            }
        }
    }

    var parent = element.closest('div');
    options.dropdownParent = parent;

    Object.assign(options, customOptions);

    element.select2( options );
}
window.initSelect2 = initSelect2;

var countries = [];
function initSelect2Phone( element )
{
    var options = {
        "language": {
            "noResults": function(){
                return "Žiadne položky";
            }
        },
        dropdownParent: parent,
        templateSelection: formatPhoneCountrySelection,
        templateResult: formatPhoneCountryResult,
        dropdownCssClass: 'select2-phone',
        matcher: CountrySearch
    }

    var parent = element.closest('div');
    options.dropdownParent = parent;

    jQuery(function() {
        $.ajax({
            url: '/assets/data/countries.json',
            dataType: 'json',
            success: function(data) {
                countries = data;

                element.select2( options );
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.error('Error loading country data:', errorThrown);
            }
        });
    });
}
window.initSelect2Phone = initSelect2Phone;

function formatPhoneCountrySelection(country) {
    if( !country.id ){
        return country.text;
    }

    var short = $(country.element).attr('data-country-short');

    var template = $('<div class="select2-flag"><img src="' + countries[short]['image'] + '" /><span class="fw-bold">' + country.text + '</span></div>');
    return template;
}

function formatPhoneCountryResult(country)
{
    if( !country.id ){
        return country.text;
    }

    var short = $(country.element).attr('data-country-short');

    var template = $('<div class="select2-flag"><img src="' + countries[short]['image'] + '" /><div class="d-flex flex-column fs--1"><span>' + countries[short]['name'] + '</span><span>' + country.text + '</span></div></div>');
    return template;
}

function CountrySearch(params, data) {
    
    if( $.trim(params.term) === '' ){
      return data;
    }

    if( typeof data.text === 'undefined' ){
      return null;
    }

    var search = params.term.toLowerCase();
    var search_string = data.text;

    if( typeof $(data.element).attr('data-country') !== undefined ){
        search_string += ' '+ $(data.element).attr('data-country');
    }

    if( typeof $(data.element).attr('data-country-short') !== undefined ){
        search_string += ' '+ $(data.element).attr('data-country-short');
    }

    search_string = search_string.toLowerCase();

    if( search_string.indexOf(search) > -1 ){
      var modifiedData = $.extend({}, data, true);
      //modifiedData.text = '<strong>'+ modifiedData.text +'</strong>';

      return modifiedData;
    }

    // Return `null` if the term should not be displayed
    return null;
}
  
function initSelect2Country( element )
{
    var parent = element.closest('div');

    var options = {
        "language": {
            "noResults": function(){
                return "Žiadne položky";
            }
        },
        dropdownParent: parent,
        templateResult: formatCountrySelection,
        templateSelection: formatCountrySelection,
        dropdownCssClass: 'select2-country'
    }

    jQuery(function() {
        $.ajax({
            url: '/assets/data/countries.json',
            dataType: 'json',
            success: function(data) {
                countries = data;

                element.select2( options );
            },
            error: function(jqXHR, textStatus, errorThrown) {
                console.error('Error loading country data:', errorThrown);
            }
        });
    });
}
window.initSelect2Country = initSelect2Country;

function formatCountrySelection(country) {
    if( !country.id ){
        return country.text;
    }

    var short = $(country.element).attr('data-country-short');

    var template = $('<div class="select2-flag country"><img src="' + countries[short]['image'] + '" /><span>' + country.text + '</span></div>');
    return template;
}

function initAirDatePicker( element, customOptions )
{
    customOptions = ( customOptions !== undefined ) ? JSON.parse(customOptions) : {};

    var input = element;
    var value = value ? moment(value, 'D. M. YYYY HH:mm') : moment();

    var options = {
        locale: window.AirDatepickerLocale,
        minutesStep: 5,
        dateFormat: 'd. M. yyyy',
        timeFormat: 'HH:mm',
        //autoClose: true,
    }

    Object.assign(options, customOptions);

    const dp = new AirDatepicker(element[0], options);
}
window.initAirDatePicker = initAirDatePicker;

function initDropzone( element, customOptions )
{
    var name = $(element).attr('data-name');
    var form = $(element).closest('form');
    var submitButton = form.find('button.form-submit');

    customOptions = ( customOptions !== undefined ) ? JSON.parse(customOptions) : {};

    var options = {
        autoDiscover: false,
        dictDefaultMessage: "Kliknutím alebo presunutím sem nahrajte súbor",
    }

    if( customOptions.hasOwnProperty('uploadMultiple') && customOptions.uploadMultiple === true ){
        options.dictDefaultMessage = "Kliknutím alebo presunutím sem nahrajte súbory";
    }

    Object.assign(options, customOptions);

    var dropzone = new Dropzone(element, options);
    
    var on_success = ( customOptions.hasOwnProperty('uploadMultiple') && customOptions.uploadMultiple === true ) ? 'successmultiple' : 'success';

    dropzone.on('sending', function(file) {
        submitButton.attr('disabled', 'disabled');
    });

    dropzone.on('complete', function(file) {
        submitButton.removeAttr('disabled');
    });

    dropzone.on('addedfile', function(file) {
        if (this.files.length > 1 && options.maxFiles === 1) {
            this.removeFile(this.files[0]);
            form.find('input[name="'+name+'"]').remove();
        }
    });

    dropzone.on(on_success, function (file, response) {

        if( on_success === 'success' )
        {
            var file_ = file;
            file = [];
            file.push( file_ );
        }

        $.each( file, function( index, value ){
            var hiddenInput = document.createElement("input");
            hiddenInput.type = "hidden";
            hiddenInput.name = name;
            hiddenInput.setAttribute('data-file-name', value.name);
            hiddenInput.setAttribute('data-file-type', value.type);
            hiddenInput.setAttribute('data-file-size', value.size);

            var values = {
                name: value.name,
                type: value.type,
                size: value.size,
                temp_path: response.filePaths[index],
            }
            hiddenInput.value = JSON.stringify(values);

            form.append( hiddenInput );
        });
        
    });

    dropzone.on("removedfile", function (file) {
        form.find('input[data-file-name="'+ file.name +'"]').remove();
        if( file.id !== undefined )
        {
            form.find('input[data-id="'+ file.id +'"]').remove();
        }
    });
}
window.initDropzone = initDropzone;

/** Select icon */

$(document).on( window.clickEvent,'.form-control.select-icon', function(event){
	event.preventDefault();
	if( window.drag ) return;
    
    var parent = $(this).parent();
    var value = $(this).attr('data-option-value');

    parent.find('.select-icon').removeClass('active');
    $(this).addClass('active');

    parent.next('input[type="hidden"]').val( value );
});

$(document).on( window.clickEvent, '.app-restore-original-button', function(event){
    event.preventDefault();

    var parent = $(this).parent();
    
    parent.find('input:not(.trix-editor-hidden)').each(function() {
        var originalValue = $(this).data('restore-original');
        $(this).val(originalValue);
    });

    parent.find('select').each(function() {
        var originalValue = $(this).data('restore-original');
        $(this).val(originalValue).trigger('change');
    });

    parent.find('input.trix-editor-hidden').each(function() {
        var originalValue = $(this).data('restore-original');
        $(this).parent().find('trix-editor').html(originalValue);
    });

    parent.removeClass('app-restore-original');
});