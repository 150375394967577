$(document).on(window.clickEvent, '.gantt__row.gantt__row--tasks:not(.gantt__row--empty) .gantt__row-first', function(event){
    event.preventDefault();
	if( window.drag ) return;

    var li = $(this).parent().find('li').first();
    var left = ( li.position().left - $(this).width() - 10 ) + $(".gantt").scrollLeft();

    $(".gantt").animate( { scrollLeft: left }, 800 );
});

$(document).on(window.clickEvent, '.gantt-explanation .today', function(event){
    event.preventDefault();
	if( window.drag ) return;

    var left = $('.gantt__row--lines span.active.today').position().left;
    left = left - ( $('.gantt__row--months .gantt__row-first').width() + 10 );

    $(".gantt").animate( { scrollTop: 0, scrollLeft: left }, 800 );
});

/*
$(document).on(window.clickEvent, '.gantt__row-bars li', function(event){
    event.preventDefault();
	if( window.drag ) return;

    
});
*/