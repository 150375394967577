function getData( el, data ){
    try {
      return JSON.parse(el.dataset[camelize(data)]);
    } catch (e) {
      return el.dataset[camelize(data)];
    }
}

function camelize(str){
    const text = str.replace(/[-_\s.]+(.)?/g, (_, c) =>
      c ? c.toUpperCase() : ''
    );
    return `${text.substr(0, 1).toLowerCase()}${text.substr(1)}`;
}

/*-----------------------------------------------
  |                    Phoenix Offcanvas
  -----------------------------------------------*/

  function phoenixOffcanvasInit(){
    const toggleEls = document.querySelectorAll(
      "[data-phoenix-toggle='offcanvas']"
    );
    const offcanvasBackdrop = document.querySelector('[data-phoenix-backdrop]');

    const showFilterCol = offcanvasEl => {
      offcanvasEl.classList.add('show');
      document.body.style.overflow = 'hidden';
    };
    const hideFilterCol = offcanvasEl => {
      offcanvasEl.classList.remove('show');
      document.body.style.removeProperty('overflow');
    };

    if (toggleEls) {
      toggleEls.forEach(toggleEl => {
        const offcanvasTarget = getData(toggleEl, 'phoenix-target');
        const offcanvasTargetEl = document.querySelector(offcanvasTarget);
        const closeBtn = offcanvasTargetEl.querySelector(
          "[data-phoenix-dismiss='offcanvas']"
        );

        toggleEl.addEventListener('click', () => {
          //console.log({ offcanvasTargetEl });
          showFilterCol(offcanvasTargetEl);
        });
        if (closeBtn) {
          closeBtn.addEventListener('click', () => {
            hideFilterCol(offcanvasTargetEl);
          });
        }
        if (offcanvasBackdrop) {
          offcanvasBackdrop.addEventListener('click', () => {
            hideFilterCol(offcanvasTargetEl);
          });
        }
      });
    }
  };
  window.phoenixOffcanvasInit = phoenixOffcanvasInit;

 /* eslint-disable no-unused-expressions */
  /*-----------------------------------------------
  |   DomNode
  -----------------------------------------------*/
  class DomNode {
    constructor(node) {
      this.node = node;
    }

    addClass(className) {
      this.isValidNode() && this.node.classList.add(className);
    }

    removeClass(className) {
      this.isValidNode() && this.node.classList.remove(className);
    }

    toggleClass(className) {
      this.isValidNode() && this.node.classList.toggle(className);
    }

    hasClass(className) {
      this.isValidNode() && this.node.classList.contains(className);
    }

    data(key) {
      if (this.isValidNode()) {
        try {
          return JSON.parse(this.node.dataset[this.camelize(key)]);
        } catch (e) {
          return this.node.dataset[this.camelize(key)];
        }
      }
      return null;
    }

    attr(name) {
      return this.isValidNode() && this.node[name];
    }

    setAttribute(name, value) {
      this.isValidNode() && this.node.setAttribute(name, value);
    }

    removeAttribute(name) {
      this.isValidNode() && this.node.removeAttribute(name);
    }

    setProp(name, value) {
      this.isValidNode() && (this.node[name] = value);
    }

    on(event, cb) {
      this.isValidNode() && this.node.addEventListener(event, cb);
    }

    isValidNode() {
      return !!this.node;
    }

    // eslint-disable-next-line class-methods-use-this
    camelize(str) {
      const text = str.replace(/[-_\s.]+(.)?/g, (_, c) =>
        c ? c.toUpperCase() : ''
      );
      return `${text.substr(0, 1).toLowerCase()}${text.substr(1)}`;
    }
  }

  const elementMap = new Map();

  class BulkSelect {
    constructor(element, option) {
      this.element = element;
      this.option = {
        displayNoneClassName: 'd-none',
        ...option
      };
      elementMap.set(this.element, this);
    }

    // Static
    static getInstance(element) {
      if (elementMap.has(element)) {
        return elementMap.get(element);
      }
      return null;
    }

    init() {
      this.attachNodes();
      this.clickBulkCheckbox();
      this.clickRowCheckbox();
    }

    getSelectedRows() {
      return Array.from(this.bulkSelectRows)
        .filter(row => row.checked)
        .map(row => getData(row, 'bulk-select-row'));
    }

    attachNodes() {
      const { body, actions, replacedElement } = getData(
        this.element,
        'bulk-select'
      );

      this.actions = new DomNode(document.getElementById(actions));
      this.replacedElement = new DomNode(
        document.getElementById(replacedElement)
      );
      this.bulkSelectRows = document
        .getElementById(body)
        .querySelectorAll('[data-bulk-select-row]');
    }

    attachRowNodes(elms) {
      this.bulkSelectRows = elms;
    }

    clickBulkCheckbox() {
      // Handle click event in bulk checkbox
      this.element.addEventListener('click', () => {
        if (this.element.indeterminate === 'indeterminate') {
          this.actions.addClass(this.option.displayNoneClassName);
          this.replacedElement.removeClass(this.option.displayNoneClassName);

          this.removeBulkCheck();

          this.bulkSelectRows.forEach(el => {
            const rowCheck = new DomNode(el);
            rowCheck.checked = false;
            rowCheck.setAttribute('checked', false);
          });
          return;
        }

        this.toggleDisplay();
        this.bulkSelectRows.forEach(el => {
          el.checked = this.element.checked;
        });
      });
    }

    clickRowCheckbox() {
      // Handle click event in checkbox of each row
      this.bulkSelectRows.forEach(el => {
        const rowCheck = new DomNode(el);
        rowCheck.on('click', () => {
          if (this.element.indeterminate !== 'indeterminate') {
            this.element.indeterminate = true;
            this.element.setAttribute('indeterminate', 'indeterminate');
            this.element.checked = true;
            this.element.setAttribute('checked', true);

            this.actions.removeClass(this.option.displayNoneClassName);
            this.replacedElement.addClass(this.option.displayNoneClassName);
          }

          if ([...this.bulkSelectRows].every(element => element.checked)) {
            this.element.indeterminate = false;
            this.element.setAttribute('indeterminate', false);
          }

          if ([...this.bulkSelectRows].every(element => !element.checked)) {
            this.removeBulkCheck();
            this.toggleDisplay();
          }
        });
      });
    }

    removeBulkCheck() {
      this.element.indeterminate = false;
      this.element.removeAttribute('indeterminate');
      this.element.checked = false;
      this.element.setAttribute('checked', false);
    }

    toggleDisplay() {
      this.actions.toggleClass(this.option.displayNoneClassName);
      this.replacedElement.toggleClass(this.option.displayNoneClassName);
    }
  }

  window.BulkSelect = BulkSelect;