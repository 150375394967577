class Controller {
	constructor() {
		this.loadedScripts = [];
    	this.offcanvasTypes = ['offcanvas-default', 'offcanvas-xl', 'offcanvas-xxl'];
    	this.modalTypes = ['modal-default', 'modal-xl', 'modal-xxl'];

		this.initOffcanvas();
		this.initModal();

		this.content;
		this.setContent2Page();
	}

	/*
	init()
	{
		$(document).on(window.clickEvent, '.dialog-submit', this.dialogSubmit.bind(this));
		$(document).on(window.clickEvent, '.dialog-dismiss', this.dialogDismiss.bind(this)); 
	}
	*/

	generateUniqueId() {
		return 'controller-' + Date.now().toString(36) + Math.random().toString(36).substring(2, 7);
	}

	/**
	 * Page
	 */
	setContent2Page() {
		this.content = this.getPageContent();
	}

	getPageContent() {
		return $('main > div > div.content');
	}

	/**
	 * Offcanvas
	 */
	initOffcanvas() {
		this.offcanvas = document.querySelector('body > #offcanvas');
		this.bsOffcanvas = new bootstrap.Offcanvas(this.offcanvas);

		if( this.getOffcanvasContent().find('.offcanvas-placeholder').length !== 0 ){
			var placeholder = this.getOffcanvasContent();
			this.offcanvasPlaceholder = placeholder.html();
			placeholder.find('.offcanvas-placeholder').remove();
		}
	}

	setContent2Offcanvas() {
		this.content = this.getOffcanvasContent();
		
		this.content.removeClass('offcanvas-default').removeClass('offcanvas-xl').removeClass('offcanvas-xxl')
	}

	getOffcanvasContent() {
		return $(this.offcanvas);
	}
	
	getOffcanvasPlaceholder() {
		if( this.offcanvasPlaceholder === undefined ) return false;
		return this.offcanvasPlaceholder;
	}

	/**
	 * Modal 
	 */
	initModal() {
		this.modal = document.querySelector('body > #modal');
		this.bsModal = new bootstrap.Modal(this.modal);

		if( this.getModalContent().find('.modal-placeholder').length !== 0 ){
			var placeholder = this.getModalContent();
			this.modalPlaceholder = placeholder.html();
			placeholder.find('.modal-placeholder').remove();
		}
	}

	setContent2Modal() {
		this.content = this.getModalContent();

		this.content.removeClass('modal-default').removeClass('modal-xl').removeClass('modal-xxl')
	}

	getModalContent() {
		return $(this.modal);
	}

	getModalPlaceholder() {
		if( this.modalPlaceholder === undefined ) return false;
		return this.modalPlaceholder;
	}

	getContent() {
		return this.content;
	}
	
	/**
	 * Load
	 */
	loadScripts( response ) {
		var self = this;

		if( ( typeof response.scripts ) == 'object' && Object.keys(response.scripts).length ){
			$.each( response.scripts, function( index, value ){
				if( self.loadedScripts.indexOf( value ) !== -1 ) return;
				$.getScript(value);
	
				if( value.indexOf('js/init/') === -1 ){
					self.loadedScripts.push( value );
				}
			});
		}
	
		$.getScript('/assets/js/app-init.js');
		$.getScript('/assets/js/init/form.js');
	}

	getResponse(data)
	{
		var self = this;

		$.ajax({
			url: data.url,
			type: data.method,
			data: data.data,
			dataType: 'json',
			success: function( response ){
				
				self.setContent(response);

				if( response.pushstate !== undefined ){
					if( data.back === undefined || data.back == false )
						window.history.pushState( window.location.origin, 'Fox Soft', data.href );
				}

				self.loadScripts( response );
	
				Pace.stop();
	
				//if( callback ) callback();
			},
			error: function(jqXHR, textStatus, errorThrown) {
				self.content.html( jqXHR.responseText );
				console.log(textStatus, errorThrown);
				//location.href = data.href;
			}
		});
	}

	link( element )
	{
		var url = element.attr('data-page'),
			type = element.attr('data-page-type'),
			href2data = this.href2data( url );

		if( type === undefined ) return;

		var data = {
			url: href2data.url,
			type: type,
			method: 'get',
			data: href2data.params,
			href: href2data.href
		};

		var showType = type.match(/^[^-]*/)[0];
		showType = showType.charAt(0).toUpperCase() + showType.substring(1);
		
		var setContentFunction = 'setContent2' + showType;
		var showFunction = 'show' + showType;

		Pace.start();

		$('body > div.pace').addClass('pace-active').removeClass('pace-inactive');
		$('body > div.pace > div.pace-progress').attr('style', 'transform: translate3d(10%, 0px, 0px);');

		if (typeof this[setContentFunction] === 'function') {
			this[setContentFunction]();
		}

		if (typeof this[showFunction] === 'function') {
			this[showFunction](data);
		}

		this.getResponse(data);
	}

	submit( element )
	{
		var self = this,
			form = element.closest('form'),
			data = form.serialize(),
			controller = element.attr('data-controller'),
			button = element;

		button.attr('disabled', 'disabled');
		button.html('<div class="spinner-border spinner-border-sm"></div>');

		if( button.attr('name') !== undefined ){
			data += '&' + button.attr('name');
		}

		Pace.start();

		$.ajax({
			url: form.attr('action'),
			type: form.attr('method'),
			data: data,
			dataType: 'json',
			success: function( response ){

				var setType = response.type.match(/^[^-]*/)[0];
				setType = setType.charAt(0).toUpperCase() + setType.substring(1);
				
				if( setType === 'Page' ) self.hide( controller );

				var setContentFunction = 'setContent2' + setType;
				
				if (typeof self[setContentFunction] === 'function') {
					self[setContentFunction]();
				}

				self.setContent(response);

				self.loadScripts( response );

				//processResponse( response, data );
				
				Pace.stop();

			},
			error: function(jqXHR, textStatus, errorThrown) {
				self.content.html( jqXHR.responseText );
				console.log(textStatus, errorThrown);
			}
		});

	}

	showPage()
	{
		
	}

	showModal()
	{
		this.content.html( this.getModalPlaceholder() );
		this.bsModal.show();
	}

	showOffcanvas()
	{
		this.content.html( this.getOffcanvasPlaceholder() );
		this.bsOffcanvas.show();
	}

	setContent( response )
	{
		$.each(response.toasts, function( index, value ){
			var data = {
				className: 'toastify-soft-' + value.class,
				text: value.message
			}

			window.showToast( data );
		});

		$(this.content).removeClass(function (index, className) {
			return (className.match(/\bcontroller-\S+/g) || []).join(' ');
		});

		var uniqueId = this.generateUniqueId();
		this.content.addClass( uniqueId );
		this.content.html( response.content );

		if( response.type == 'page' )
		{
			if( response.heading !== undefined ) this.content.prepend( response.heading );
			if( response.breadcrumbs !== undefined ) this.content.prepend( response.breadcrumbs );	
		}

		if( this.content.hasClass('modal') ) this.content.addClass( response.type );

		this.content.find('.dialog-submit').attr('data-controller', uniqueId);
		this.content.find('.dialog-dismiss').attr('data-controller', uniqueId);
	}
	
	hide( controller )
	{
		controller = $('.'+ controller );

		controller.html('');

		if( controller.hasClass('modal') ) this.bsModal.hide();
		if( controller.hasClass('offcanvas') ) this.bsOffcanvas.hide();
	}

	href2data( href )
	{
		var params = [];
		var url = new URL(href);
		var pages = (url.pathname).split('/');
		var searchParams = new URLSearchParams( url.search );
		searchParams.forEach(function(value, key) {
			params.push( [key,value] );
		});
		params.push( ['backUrl',window.location.href] );
		params = Object.fromEntries(params)

		var data = {
			url: url.origin + url.pathname,
			pages: pages,
			params: params,
			href: url.href
		};

		return data;
	}
	
}

const MyController = new Controller();

$(document).on(window.clickEvent, '.ajax-link', function( event ){
	if( window.cntrlIsPressed ) return;
	
	event.preventDefault();
	if( window.drag ) return;

	MyController.link( $(this) );
});

$(window).on('popstate', function (e) {
	var href = e.originalEvent.target.location.href;

	var pageData = {
		url: href,
		data: [],
		back: true
	};

	MyController.getResponse( pageData );
});

$(document).on(window.clickEvent, '.dialog-submit', function( event ){
	if( $(this).closest('.bd-example').length > 0 ) return;
	
	event.preventDefault();
	if( window.drag ) return;
	if( $(this).prop('disabled') ) return;

	MyController.submit( $(this) );
});

$(document).on(window.clickEvent, '.dialog-dismiss', function( event ){
	if( $(this).closest('.bd-example').length > 0 ) return;

	event.preventDefault();
	if( window.drag ) return;

	var controller = $(this).attr('data-controller');

	MyController.hide( controller );
});

$(document).on(window.clickEvent, '.form-password-generate', function( event ){
	event.preventDefault();
	if( window.drag ) return;

	var button = $(this),
		text = button.html(),
		input_password = $(this).closest('form').find('.input-password-generate');

	button.html('<div class="spinner-border spinner-border-sm"></div>');

	$.ajax({
		url: '/helper/password/random',
		type: 'get',
		data: [],
		dataType: 'json',
		success: function( response ){
			if( response.error ){
				return;
			}

			var password = response.data.password;

			input_password.attr({type: "text"});
			input_password.val(password);

			button.html( text );
		},
		error: function(jqXHR, textStatus, errorThrown) {
			console.log( jqXHR.responseText );
			console.log(textStatus, errorThrown);
		}
	});
});

