

function clearBreakpoints( content, size )
{
    if( size == 'sm' )
    {
        content.find('.col-md-4,.col-md-8').each( function(){
            $(this)
                .removeClass('col-md-4')
                .removeClass('col-md-8');
        });
    }

    if( size == 'sm' || size == 'mg' )
    {
        content.find('.col-lg-4,.col-lg-8').each( function(){
            $(this)
                .removeClass('col-lg-4')
                .removeClass('col-lg-8');
        });

        content.find('.px-lg-2,.pe-lg-3,.py-lg-0,.px-lg-3,.pb-lg-0,.pt-lg-0,.ps-lg-3').each( function(){
            $(this)
                .removeClass('px-lg-2')
                .removeClass('pe-lg-3')
                .removeClass('py-lg-0')
                .removeClass('pb-lg-0')
                .removeClass('pt-lg-0')
                .removeClass('ps-lg-3')
                .removeClass('px-lg-3');
        });

        content.find('.border-top-lg-0,.border-start-lg').each( function(){
            $(this)
                .removeClass('border-top-lg-0')
                .removeClass('border-start-lg');
        });
        
        content.find('.d-lg-none,.d-lg-block').each( function(){
            $(this)
                .removeClass('d-lg-none')
                .removeClass('d-lg-block');
        });
    }

    if( size == 'sm' || size == 'mg' || size == 'lg' )
    {
        content.find('.col-xl-3,.col-xl-9').each( function(){
            $(this)
                .removeClass('col-xl-3')
                .removeClass('col-xl-9');
        });

        content.find('.me-xl-2').each( function(){
            $(this)
                .removeClass('me-xl-2');
        });
        
        content.find('.d-xl-inline').each( function(){
            $(this)
                .removeClass('d-xl-inline');
        });
    }

    if( size == 'sm' || size == 'mg' || size == 'lg' || size == 'xl' )
    {
        content.find('.d-xxl-inline').each( function(){
            $(this).removeClass('d-xxl-inline');
        });
    }
}
window.clearBreakpoints = clearBreakpoints;

