// Bootstrap's JS'
import bootstrap from '~bootstrap/dist/js/bootstrap.bundle.min.js';
window.bootstrap = bootstrap;

import $ from '~jquery/dist/jquery.min.js';
window.jQuery = window.$ = $;

import _ from '~lodash';
window._ = _

//Feather Icons
/*
import feather from '~feather-icons/dist/feather.js';
window.feather = feather;

feather.replace({
    width: '16px',
    height: '16px'
});
*/

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

/*
import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
*/

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// import Pusher from 'pusher-js';
// window.Pusher = Pusher;

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: import.meta.env.VITE_PUSHER_APP_KEY,
//     cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER ?? 'mt1',
//     wsHost: import.meta.env.VITE_PUSHER_HOST ? import.meta.env.VITE_PUSHER_HOST : `ws-${import.meta.env.VITE_PUSHER_APP_CLUSTER}.pusher.com`,
//     wsPort: import.meta.env.VITE_PUSHER_PORT ?? 80,
//     wssPort: import.meta.env.VITE_PUSHER_PORT ?? 443,
//     forceTLS: (import.meta.env.VITE_PUSHER_SCHEME ?? 'https') === 'https',
//     enabledTransports: ['ws', 'wss'],
// });

import Pace from '~pace-js/pace.min.js';
window.Pace = Pace;

import { createPopper } from '~@popperjs/core';
window.createPopper = createPopper;

/*
import AnchorJS from '~anchor-js';
window.AnchorJS = AnchorJS;

import is from '~is_js';
window.is = is;
*/

/*
import List from '~list.js';
window.List = List;
*/

import moment from '~moment/min/moment-with-locales.min.js';
window.moment = moment;

/*
import dayjs from '~dayjs';
window.dayjs = dayjs;

import { Calendar } from '~fullcalendar';
window.FullCalendar = Calendar;

import prismjs from '~prismjs';
window.prismjs = prismjs;
*/
import AirDatepicker from '~air-datepicker';
window.AirDatepicker = AirDatepicker;
import localeSk from '~air-datepicker/locale/sk';
window.AirDatepickerLocale = localeSk;
import '~air-datepicker/air-datepicker.css';

import Dropzone from "dropzone";
window.Dropzone = Dropzone;

import select2 from '~select2';
select2(window, $);

import scrollTo from '~jquery.scrollto/jquery.scrollTo.js';
window.$.fn.scrollTo = scrollTo;

if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
    window.clickEvent = 'touchend';
} else {
    window.clickEvent = 'click';
}

$( document ).on("touchmove", function(){
    window.drag = true;
});

$( document ).on("touchstart", function(){
    window.drag = false;
});
window.drag = false;

$( document ).keydown(function(event){
    if(event.which=="192")
        window.cntrlIsPressed = true;
});

$( document ).keyup(function(){
    window.cntrlIsPressed = false;
});
window.cntrlIsPressed = false;