import Toastify from '~toastify-js';

function showToast( data )
{
    var toast = Toastify({
        text: data.text,
        escapeMarkup: false,
        //duration: 3000,
        duration: 9999,
        close: true,
        className: data.className,
        gravity: "bottom",
        stopOnFocus: true,
        position: "center",
        transition: "flip",
        onClick: function(){
            toast.hideToast();
        }
    });
    
    toast.showToast();
}
window.showToast = showToast;

$('.toastify-notify').each( function( index, element ){
    var className = $(element).attr('data-class');
    var text = $(element).html().trim();
    var data = {
        className: className,
        text: text
    };

    showToast( data );

    /*
    var toast = Toastify({
        text: text,
        escapeMarkup: false,
        //duration: 3000,
        duration: 9999,
        close: true,
        className: className,
        gravity: "bottom",
        stopOnFocus: true,
        position: "center",
        transition: "flip",
        onClick: function(){
            toast.hideToast();
        }
    });
    
    toast.showToast();
    */
});